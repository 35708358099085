<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <app-breadcrumb
      page-title="Создание страницы"
      :breadcrumb="breadcrumb"
    />
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Активность"
              label-for="is-published"
            >
              <b-form-checkbox
                id="is-published"
                v-model="item.is_published"
                :state="get(validation, 'is_published[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.is_published">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'is_published[0]')"
                class="text-danger"
              >
                {{ get(validation, 'is_published[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"

                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group

              label="Slug"
              label-for="slug"
              description="Если не заполнен, генерируется автоматически на основе названия. Должен состоять не менее чем из 3 и не более чем из 255 символов (a-z, 0-9, -)."
            >
              <b-form-input
                id="slug"
                v-model.trim="item.slug"
                v-mask="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$', placeholder: '' }"
                placeholder="Slug"
                :state="get(validation, 'slug[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'slug[0]')"
                class="text-danger"
              >
                {{ get(validation, 'slug[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <h4>
              SEO
            </h4>
          </b-col>

          <b-col
            cols="12"
          >
            <seo-fields-card
              :title.sync="item.dev_data.meta.title"
              :description.sync="item.dev_data.meta.description"
              :image.sync="item.dev_data.meta.image"
            />
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BFormTextarea, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import get from 'lodash/get'
import {
  getCurrentInstance, ref, computed, nextTick,
} from 'vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import useCrudCreate from '@/composables/useCrudCreate'
import { getWebsite as getWebsiteApi } from '@/services/main-api/websites/stores/websites'
import { createPage } from '@/services/main-api/websites/stores/pages'
import SeoFieldsCard from '@/modules/sites-section/site-store-section/pages/components/SeoFieldsCard.vue'

export default {
  name: 'CreateWarehouseView',
  components: {
    SeoFieldsCard,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    AppBreadcrumb,
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    // BEGIN Site
    const site = ref(null)
    const resItem = ref(null)

    const getSite = async () => {
      try {
        site.value = (await getWebsiteApi({
          id: props.siteId,
        })).data.data
      } catch (e) {
        site.value = null
      }
    }
    // END Site

    const createEntity = async () => {
      const res = await createPage({
        website_id: props.siteId,
        name: instance.item.name,
        is_published: instance.item.is_published,
        slug: instance.item.slug,
        dev_data: instance.item.dev_data,
      })

      resItem.value = res.data.data
    }

    // BEGIN breadcrumb
    const breadcrumb = computed(() => [
      {
        text: 'Сайты',
        to: {
          name: 'sites',
        },
      },
      {
        text: get(site.value, 'name', 'Сайт'),
        to: {
          name: 'sites.store.show',
          params: {
            id: props.siteId,
          },
        },
      },
      {
        text: 'Создание страницы',
        active: true,
      },
    ])
    // END breadcrumb

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
        is_published: false,
        slug: '',
        dev_data: {
          meta: {
            title: '',
            description: '',
            image: '',
          },
        },
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      createEntity,
      getMoreData: async () => {
        await getSite()
      },
      getTextAfterCreation: () => 'Страница была создана.',
      runAfterCreation: async () => {
        await nextTick()
        if (resItem.value) {
          await instance.$router.push({ name: 'sites.store.pages.content', params: { id: resItem.value.id } })
        }
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,

      breadcrumb,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,
    }
  },
  props: {
    siteId: {
      type: [Number, String],
      required: true,
    },
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
