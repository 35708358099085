import axiosIns from '@/libs/axios'

export function getPage({ id, includes }) {
  return axiosIns.get(`/websites/stores/pages/${id}`, {
    params: {
      includes,
    },
  })
}

export function getPages({
  websiteID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/stores/pages', {
    params: {
      websiteID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createPage({
  website_id,
  name,
  slug,
  is_published,
  dev_data,
}) {
  return axiosIns.post('/websites/stores/pages', {
    website_id,
    name,
    slug,
    is_published,
    dev_data,
  })
}

export function updatePage({
  id,
  is_published,
  name,
  slug,
  dev_data,
}) {
  return axiosIns.patch(`/websites/stores/pages/${id}`, {
    is_published,
    name,
    slug,
    dev_data,
  })
}

export function deletePage(id) {
  return axiosIns.delete(`/websites/stores/pages/${id}`)
}

export default {
  getPages,
  getPage,
  createPage,
  updatePage,
  deletePage,
}
