<template>
  <div class="seo-fields-card">
    <div class="seo-fields-card__image-field">
      <b-button
        variant="flat-secondary"
        class="btn-icon seo-fields-card__image-field__edit-button"
        size="sm"
      >
        <feather-icon icon="Edit2Icon" />
      </b-button>
      <div
        v-if="false"
        class="seo-fields-card__image-field__image"
      >
        1
      </div>
      <div
        v-else
        class="seo-fields-card__image-field__image-placeholder"
      >
        <feather-icon
          icon="ImageIcon"
          class="seo-fields-card__image-field__image-placeholder__icon"
        />
      </div>
    </div>
    <div class="seo-fields-card__fields">
      <div class="seo-fields-card__field">
        <b-form-group
          v-if="isEditedTitle"

          label="Title"
          label-for="seo-title"
          class="mb-0"
        >
          <b-form-input
            id="seo-title"
            ref="titleField"
            v-model.trim="titleValue"
            placeholder="Title"
            @blur="closeEditTitle"
          />
        </b-form-group>
        <div
          v-else
          class="seo-fields-card__field__view"
        >
          <div class="seo-fields-card__field__view__title">
            {{ titleValue ? titleValue : 'Title' }}
          </div>
          <div class="seo-fields-card__field__view__control">
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              size="sm"
              @click="editTitle"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="seo-fields-card__field">
        <b-form-group
          v-if="isEditedDescription"

          label="Description"
          label-for="seo-description"
          class="mb-0"
        >
          <b-form-textarea
            id="seo-description"
            ref="descriptionField"
            v-model="descriptionValue"
            placeholder="Description"
            @blur="closeEditDescription"
          />
        </b-form-group>
        <div
          v-else
          class="seo-fields-card__field__view"
        >
          <div class="seo-fields-card__field__view__text">
            {{ descriptionValue ? descriptionValue : 'Description' }}
          </div>
          <div class="seo-fields-card__field__view__control">
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              size="sm"
              @click="editDescription"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ref, nextTick, watch } from 'vue'

export default {
  name: 'SeoFieldsCard',
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BFormTextarea,
  },
  setup(props, { emit }) {
    const titleValue = ref('')
    const descriptionValue = ref('')

    const isEditedTitle = ref(false)
    const isEditedDescription = ref(false)

    const titleField = ref(null)
    const descriptionField = ref(null)

    const editTitle = async () => {
      isEditedTitle.value = true
      await nextTick()
      titleField.value.focus()
    }

    const closeEditTitle = () => {
      isEditedTitle.value = false
    }

    const editDescription = async () => {
      isEditedDescription.value = true
      await nextTick()
      descriptionField.value.focus()
    }

    const closeEditDescription = () => {
      isEditedDescription.value = false
    }

    watch(
      () => props.title,
      value => {
        titleValue.value = value
      },
    )

    watch(
      () => props.description,
      value => {
        descriptionValue.value = value
      },
    )

    watch(
      () => titleValue.value,
      value => {
        if (value !== props.title) {
          emit('update:title', value)
        }
      },
    )

    watch(
      () => descriptionValue.value,
      value => {
        if (value !== props.description) {
          emit('update:description', value)
        }
      },
    )

    return {
      isEditedTitle,
      isEditedDescription,
      editTitle,
      editDescription,
      closeEditTitle,
      closeEditDescription,

      titleField,
      descriptionField,

      titleValue,
      descriptionValue,
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.seo-fields-card{
  background: #FFFFFF;
  box-shadow: 0 4px 18px rgba(75, 70, 92, 0.1);
  border-radius: 6px;
  max-width: 450px;
  &__fields{
    padding: 20px 24px;
    display: grid;
    gap: 15px;
  }

  &__field{
    &__view{
      display: flex;
      align-items: center;
      &__title{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height:normal;
      }
      &__text{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
      &__control{
        margin-left: 12px;
      }
    }
  }
  &__image-field{
    position: relative;
    border-radius: 6px 6px 0 0;
    height: 0;
    padding-bottom: calc(100%/450*200);
    &__image{

    }
    &__edit-button{
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 1;
      background: rgba(255, 255, 255, 0.5);
      &:hover{
        background: rgba(255, 255, 255, 0.8) !important;
      }
    }
    &__image-placeholder{
      border-radius: 6px 6px 0 0;
      background: linear-gradient(50.59deg, #8DD3FF 0%, #20A9FF 91.96%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &__icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #fff;
        width: 40%;
        height: 40%;
      }
    }
  }

}
</style>
